exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-documents-js": () => import("./../../../src/pages/documents.js" /* webpackChunkName: "component---src-pages-documents-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-sponsorship-js": () => import("./../../../src/pages/sponsorship.js" /* webpackChunkName: "component---src-pages-sponsorship-js" */),
  "component---src-templates-headline-article-template-js": () => import("./../../../src/templates/headline-article-template.js" /* webpackChunkName: "component---src-templates-headline-article-template-js" */),
  "component---src-templates-news-article-template-js": () => import("./../../../src/templates/news-article-template.js" /* webpackChunkName: "component---src-templates-news-article-template-js" */),
  "component---src-templates-team-page-template-js": () => import("./../../../src/templates/team-page-template.js" /* webpackChunkName: "component---src-templates-team-page-template-js" */)
}

